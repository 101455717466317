import React, { useState, useContext, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import classNames from 'classnames'
import Container from '../system/container/container'
import { colors } from '../../styles/theme'
import Nav from './nav'
import PACLogo from './pac-logo'
import IconButton from '../system/forms/icon-button'
import { IconType } from '../system/icons'
import { useBanner } from '../system/banner/banner-provider'
import { HeaderHeightContext } from './header-provider'

const headerHeight = '75px'

const StyledHeader = styled.header`
  background: ${colors.white};
  width: 100%;
  z-index: 100;
  box-shadow: ${(props) => props.theme.shadows.close};

  ::before {
    display: block;
    content: '';
    background: ${(props) => props.theme.colors.background};
    width: 100%;
    height: 5px;

    ${({ withStripe }: { withStripe: boolean }) =>
      !withStripe &&
      css`
        display: none;
      `}

    @media (max-width: ${(props) => props.theme.breakpoints.navbar}) {
      display: none;
    }
  }

  a {
    color: ${(props) => props.theme.colors.typography.dark.text};
    text-transform: uppercase;
    font-weight: normal;
  }
`

const SmallHeader = styled(Container)`
  display: grid;
  height: ${headerHeight};
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;

  @media (min-width: ${(props) => props.theme.breakpoints.navbar}) {
    display: none;
  }
`

const StyledNav = styled(Nav)`
  @media (max-width: ${(props) => props.theme.breakpoints.navbar}) {
    display: none;

    &.expanded {
      display: grid;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.navbar}) {
    height: ${headerHeight};
  }
`

const Header = () => {
  const [navExpanded, setNavExpanded] = useState(false)
  const banner = useBanner()

  const ref = useRef(null)
  const { setHeight } = useContext(HeaderHeightContext)
  useEffect(() => {
    const handleResize = () => {
      const height = ref?.current?.clientHeight
      if (height) {
        setHeight(height)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [navExpanded])

  return (
    <StyledHeader withStripe={!banner} ref={ref}>
      <SmallHeader>
        <PACLogo />

        <IconButton
          aria-expanded={navExpanded}
          aria-label="Mobile Navigation Button"
          icon={navExpanded ? IconType.ExpandUp : IconType.Hamburger}
          onClick={() => setNavExpanded(!navExpanded)}
        />
      </SmallHeader>

      <StyledNav className={classNames({ expanded: navExpanded })} />
    </StyledHeader>
  )
}

export default Header
