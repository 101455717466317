import React from 'react'
import Head from 'next/head'

const Meta = ({
  title,
  description,
  children
}: {
  title?: string
  description?: string
  children?: React.ReactNode
}) => {
  const siteName = 'AISD Performing Arts Center'
  const metaDescription =
    description ||
    `Experience concerts, stunning performances, and educational events in the elegantly designed Allen ISD Performing Arts Center.`

  return (
    <Head>
      <title>{title || siteName}</title>
      <meta name="description" content={metaDescription} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content="@AisdPAC" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:latitude" content="33.1096162" />
      <meta property="og:longitude" content="-96.66063810000003" />
      <meta property="og:street-address" content="300 Rivercrest Blvd" />
      <meta property="og:locality" content="Allen" />
      <meta property="og:region" content="TX" />
      <meta property="og:postal-code" content="75002" />
      <meta property="og:country-name" content="United States" />
      <meta property="og:type" content="website" />

      {process.env.NODE_ENV === 'production' && (
        <meta httpEquiv="Content-Security-Policy" content="upgrade-insecure-requests" />
      )}

      <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicons/favicon-32x32-light.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicons/favicon-16x16-light.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" color="#071a2a" />
      <link rel="shortcut icon" href="/favicons/favicon-light.ico" />
      <meta name="msapplication-TileColor" content="#2b5797" />
      <meta name="msapplication-config" content="/browserconfig.xml" />
      <meta name="theme-color" content="#071a2a" />

      <link
        href="https://fonts.googleapis.com/css?family=Open+Sans:400,700|Raleway:300,700&display=swap"
        rel="stylesheet"
      />

      {children}
    </Head>
  )
}

export default Meta
