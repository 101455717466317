import React from 'react'
import styled from 'styled-components'
import Icon, { IconType } from '../icons'

const StyledButton = styled.button`
  background: none;
  border: 0;
  color: inherit;
  width: auto;
  outline: none;

  padding: 5px;
  border-radius: 2px;
  cursor: pointer;
  transition: background-color 300ms ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  icon: IconType
}

const IconButton = ({ icon, ...props }: ButtonProps) => (
  <StyledButton {...props}>
    <Icon type={icon} />
  </StyledButton>
)

export default IconButton
