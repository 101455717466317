import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

const Favicon = () => {
  let initialColorScheme: 'light' | 'dark' = 'light'
  let dmQuery: MediaQueryList

  if (typeof window !== 'undefined') {
    dmQuery = window.matchMedia('(prefers-color-scheme: dark)')

    if (dmQuery.matches) {
      initialColorScheme = 'dark'
    }
  }

  const [colorScheme, setColorScheme] = useState<'light' | 'dark'>(initialColorScheme)

  useEffect(() => {
    if (typeof window !== 'undefined' && dmQuery) {
      const listener = (e: MediaQueryListEvent) => {
        setColorScheme(e.matches ? 'dark' : 'light')
      }

      dmQuery.addListener(listener)

      return () => dmQuery.removeListener(listener)
    }
  }, [])

  return (
    <Helmet>
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`/favicons/favicon-32x32-${colorScheme}.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`/favicons/favicon-16x16-${colorScheme}.png`}
      />
      <link rel="shortcut icon" href={`/favicons/favicon-${colorScheme}.ico`} />
    </Helmet>
  )
}

export default Favicon
