import React from 'react'
import styled, { createGlobalStyle, css } from 'styled-components'
import theme, { colors } from '../../styles/theme'
import Header from '../header/header'
import Footer from '../footer/footer'
import { ModalProvider } from '../system/modal/modal-context'
import Favicon from '../system/favicon'
import { H1Style, H2Style, H3Style, H4Style, H5Style, H6Style } from '../../styles/headings'
import { HelmetProvider } from 'react-helmet-async'

export const LinkStyle = css`
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;

  color: ${theme.colors.linkColor};
  transition: color 100ms ease;

  &:hover {
    color: ${theme.colors.linkHoverColor};
  }

  &:active {
    color: ${theme.colors.linkActiveColor};
  }
`

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'TiemposHeadline';
    src: url('/fonts/TiemposHeadlineWeb-Bold.woff') format('woff'),
      url('/fonts/TiemposHeadlineWeb-Bold.woff2') format('woff2'),
      url('/fonts/TiemposHeadlineWeb-Bold.eot') format('eot');
  }


  html {
    box-sizing: border-box;
    font-family: ${theme.fontFamily.bodyCopy};
    line-height: 1.75;
    background: ${theme.colors.background};
    color: ${theme.colors.typography.dark.text}
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    padding: 0;
  }

  b, strong {
    font-weight: bold;
  }

  p {
    color: ${colors.neutral700};
  }

  a {
    ${LinkStyle}

    &.unstyled {
      font-weight: unset;
      color: unset;
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  h1 {
    ${H1Style}
  }

  h2 {
    ${H2Style}
  }

  h3 {
    ${H3Style}
  }

  h4 {
    ${H4Style}
  }

  h5 {
    ${H5Style}
  }

  h6 {
    ${H6Style}
  }

  hr {
    border: 1.25px solid ${theme.colors.separator};
    background-color: ${theme.colors.separator};
  }

  dl {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: ${theme.spacing.base300};
    align-items: center;
    color: ${colors.neutral700};

    dt {
      display: flex;
      justify-content: center;
    }

    dd {
      margin-left: 0;
    }
  }

  fieldset {
    display: contents;
  }

  input:disabled, textarea:disabled, select:disabled {
    position: relative;
    cursor: not-allowed;
    background-color: ${colors.neutral300};
    color: ${colors.neutral700};
  }

  .sr-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`

const Content = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const Main = styled.main`
  background-color: ${theme.colors.bodyBackground};
  flex-grow: 1;
`

const Layout = ({
  extendedFooter = false,
  children,
  ...other
}: {
  extendedFooter?: boolean
  children: any
}) => (
  <HelmetProvider>
    <ModalProvider>
      <Favicon />
      <GlobalStyle />
      <Content>
        <Header />
        <Main {...other}>{children}</Main>
        <Footer extended={extendedFooter} />
      </Content>
    </ModalProvider>
  </HelmetProvider>
)

export default Layout
