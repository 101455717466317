import React from 'react'
import styled, { css } from 'styled-components'
import PACLogoCentered from './pac-logo-centered.svg'
import { fontSizes } from '../../styles/theme'
import Container from '../system/container/container'
import breakpoints from '../../styles/breakpoints'
import Link from 'next/link'
import { FaFacebook, FaInstagram, FaTwitter, FaPhone } from 'react-icons/fa'

const overlayColor = 'rgba(11,36,60,0.97)'

const FooterBackground = styled.div`
  background: linear-gradient(${overlayColor}, ${overlayColor}),
    url('/images/textures/PAC-Walls.jpg');
  background-color: ${props => props.theme.colors.footerOverlay};

  ${({ extended }: { extended: boolean }) =>
    extended &&
    css`
      @media (min-width: ${breakpoints.md}) {
        padding-top: ${props => props.theme.spacing.base800};
      }
    `}
`

const FooterContainer = styled(Container)`
  display: grid;
  grid-template-columns: 1fr;
  align-items: start;
  justify-items: center;
  text-align: center;
  line-height: ${fontSizes.lineHeight300};

  @media (min-width: ${breakpoints.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    text-align: left;

    > :nth-child(2),
    > :nth-child(4) {
      width: 170px;
    }
  }

  @media (min-width: ${breakpoints.lg}) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    line-height: ${fontSizes.lineHeight400};

    > :nth-child(2),
    > :nth-child(4) {
      width: unset;
    }
  }

  color: ${props => props.theme.colors.typography.light.text};
  padding-top: ${props => props.theme.spacing.base600};
  padding-bottom: ${props => props.theme.spacing.base600};

  h1:not(:first-of-type) {
    margin-top: ${props => props.theme.spacing.base700};
  }

  ul {
    margin: 0;

    li a {
      color: ${props => props.theme.colors.typography.light.text};
      font-weight: normal;
    }
  }

  img {
    width: 100%;
    padding: 15%;
  }
`

const PACLogoContainer = styled.div`
  width: 50%;

  @media (min-width: ${breakpoints.lg}) {
    width: 75%;
  }
`

const SocialList = styled.ul`
  display: flex;
  justify-content: space-between;
  font-size: ${fontSizes.size300};
  margin-top: ${props => props.theme.spacing.base300} !important;

  li,
  a {
    line-height: 1em;
  }
`

const PhoneLink = styled.a`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  column-gap: ${props => props.theme.spacing.base200};
`

const Address = styled.address`
  font-style: normal;
`

const StyledCopyright = styled.div`
  text-align: center;
  color: ${props => props.theme.colors.typography.light.subtext};
  padding: 10px 0;
`

const Footer = ({ extended, ...other }: { extended: boolean }) => (
  <footer>
    <FooterBackground extended={extended} {...other}>
      <FooterContainer>
        <PACLogoContainer>
          <PACLogoCentered />
        </PACLogoContainer>
        <section>
          <h3>Quick Access</h3>
          <ul>
            <li>
              <Link href="/">
                Home
              </Link>
            </li>
            <li>
              <Link href="/events">
                Events &amp; Tickets
              </Link>
            </li>
            <li>
              <Link href="/contact">
                Contact Us
              </Link>
            </li>
            <li>
              <Link href="/health">
                Health &amp; Safety
              </Link>
            </li>
          </ul>
        </section>
        <section>
          <h3>About Us</h3>
          <ul>
            <li>
              <Link href="/venue-info#pac">
                Performing Arts Center
              </Link>
            </li>
            <li>
              <Link href="/venue-info#lowery">
                Lowery Auditorium
              </Link>
            </li>
            <li>
              <a href="https://www.allenisd.org/" rel="noopener">
                Allen ISD
              </a>
            </li>
          </ul>
        </section>
        <section>
          <h3>Keep In Touch</h3>
          <ul>
            <li>
              <PhoneLink href="tel:+14693196390">
                <FaPhone title="Phone Number" /> <span>(469) 319-6390</span>
              </PhoneLink>
            </li>
            <li>
              <a
                href="https://goo.gl/maps/vasbFwd8mQyNZREw5"
                target="_blank"
                rel="noopener"
                aria-label="View on Google Maps"
              >
                <Address>
                  300 Rivercrest Blvd
                  <br />
                  Allen, Texas 75002
                </Address>
              </a>
            </li>
            <li>
              <SocialList>
                <li>
                  <a href="https://www.instagram.com/aisdpac/" target="_blank" rel="noopener">
                    <FaInstagram title="Instagram" />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/AisdPAC/" target="_blank" rel="noopener">
                    <FaFacebook title="Facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/aisdpac" target="_blank" rel="noopener">
                    <FaTwitter title="Twitter" />
                  </a>
                </li>
              </SocialList>
            </li>
          </ul>
        </section>
      </FooterContainer>
    </FooterBackground>
    <StyledCopyright>© {new Date().getFullYear()} Allen ISD Performing Arts Center</StyledCopyright>
  </footer>
)

export default Footer
