import { useContext } from 'react'
import ModalContext from './modal-context'

const useModal = () => {
  const { isOpen, setIsOpen, content, setContent } = useContext(ModalContext)

  return {
    isModalOpen: isOpen,
    setModalOpen: setIsOpen,
    modalContent: content,
    setModalContent: setContent
  }
}

export default useModal
