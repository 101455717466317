import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import PACLogoImg from './pac-logo.svg'

const StyledPACLogo = styled(PACLogoImg)`
  display: grid;
  height: 50px;
`

const PACLogo = (props: any) => (
  (<Link href="/" {...props}>

    <StyledPACLogo aria-labelledby="title" />

  </Link>)
)

export default PACLogo
