import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import PACLogo from './pac-logo'
import Container from '../system/container/container'

const NavList = styled(Container)`
  display: grid;
  align-items: center;
  text-align: center;
  border-top: 1.25px solid ${props => props.theme.colors.separator};
  width: 100%;
  height: 100%;

  @media (max-width: ${props => props.theme.breakpoints.navbar}) {
    > * {
      padding: ${props => props.theme.spacing.base300};
    }

    > :not(:last-of-type) {
      border-bottom: 1.25px solid ${props => props.theme.colors.separator};
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.navbar}) {
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));

    li:first-of-type {
      text-align: left;
    }

    li:last-of-type {
      text-align: right;
    }
  }
`

const CenteredPACLogo = styled.li`
  display: none;
  justify-self: center;

  @media (min-width: ${props => props.theme.breakpoints.navbar}) {
    display: block;
  }
`

const Nav = ({ ...props }) => (
  <nav {...props}>
    <NavList as="ul">
      <li>
        <a href="https://www.allenisd.org" target="_blank" rel="noopener">
          <b>Allen</b> ISD
        </a>
      </li>
      <li>
        <Link href="/venue-info">
          <b>Venue</b> Info
        </Link>
      </li>
      <CenteredPACLogo>
        <PACLogo />
      </CenteredPACLogo>
      <li>
        <Link href="/events">
          <b>Events</b> &amp; Tickets
        </Link>
      </li>
      <li>
        <Link href="/contact">
          <b>Contact</b> Us
        </Link>
      </li>
    </NavList>
  </nav>
)

export default Nav
