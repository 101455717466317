import { css } from 'styled-components'
import theme from './theme'
import breakpoints from './breakpoints'

const BaseStyle = css`
  font-family: ${theme.fontFamily.headline};

  @media (max-width: ${breakpoints.md}) {
    letter-spacing: -0.03em;
  }
`

export const H1Style = css`
  ${BaseStyle}

  font-size: 36px;
  line-height: 100%;

  @media (min-width: ${breakpoints.sm}) {
    font-size: 48px;
  }
`

export const H2Style = css`
  ${BaseStyle}

  font-size: 28px;
  line-height: 125%;

  @media (min-width: ${breakpoints.sm}) {
    font-size: 36px;
    line-height: 115%;
  }
`

export const H3Style = css`
  ${BaseStyle}

  font-size: 24px;
  line-height: 125%;
`

export const H4Style = css`
  ${BaseStyle}

  font-size: 20px;
  line-height: 135%;
`

export const H5Style = css`
  ${BaseStyle}

  font-size: 18px;
  line-height: 150%;
`

export const H6Style = css`
  ${BaseStyle}

  font-size: 16px;
  line-height: 175%;
`
