import React, { useState } from 'react'
import Modal from './modal'

export type ModalState = {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  content: any
  setContent: (content: any) => void
}

const modalDefaults: ModalState = {
  isOpen: false,
  setIsOpen: () => {},
  content: null,
  setContent: () => {}
}

const ModalContext = React.createContext<ModalState>(modalDefaults)

export const ModalProvider = ({ children }: { children: any }) => {
  const [isOpen, setIsOpen] = useState(modalDefaults.isOpen)
  const [content, setContent] = useState<any>(modalDefaults.content)

  const modalState: ModalState = {
    isOpen,
    setIsOpen,
    content,
    setContent
  }

  return (
    <ModalContext.Provider value={modalState}>
      <Modal />
      {children}
    </ModalContext.Provider>
  )
}

export default ModalContext
