import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import Icon, { IconType } from '../icons'
import useModal from './use-modal'

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;

  z-index: 1000;

  ${({ isOpen }: { isOpen: boolean }) =>
    !isOpen &&
    css`
      display: none;
    `}
`

export const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 95%;
  max-height: 95%;
  background-color: ${props => props.theme.colors.bodyBackground};
  padding: ${props => props.theme.spacing.base300};
  border-radius: 5px;
`

const CloseIcon = styled(Icon)`
  position: absolute;
  width: 30px;
  height: 30px;
  top: 0;
  right: 0;
  margin-top: ${props => props.theme.spacing.base200};
  margin-right: ${props => props.theme.spacing.base200};
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  z-index: 1001;

  &:hover {
    color: rgba(0, 0, 0, 0.9);
  }
`

const Modal = () => {
  const { isModalOpen, setModalOpen, modalContent } = useModal()

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === 27) {
      setModalOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)

    return () => document.removeEventListener('keydown', handleKeyDown, false)
  }, [])

  return (
    <Background
      onClick={() => setModalOpen(false)}
      role="dialog"
      aria-modal="true"
      isOpen={isModalOpen}
    >
      <CloseIcon type={IconType.ModalClose} />
      {modalContent}
    </Background>
  )
}

export default Modal
